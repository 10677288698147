import React, { useContext } from "react";
import { useState, useEffect } from "react";
import { TableTopBar } from "../../../../../../Components/FinalTable";
import BtnRounded from "../../../../../../Components/Buttons/BtnRounded";
import Comment from "../../Components/ToolTip/Comment";
import { BsArrowRight } from "react-icons/bs";
import ViewComment from "./Modals/ViewComment";
import AddAllergiesModal from "./Modals/AddAllergiesModal";
import AllContext from "./context/AllContext";
import AllergiesTable from "./All/index";
import { useParams, useNavigate } from "react-router-dom";
import useAxios from "../../../../../../Hooks/useAxios";
import Fuse from "fuse.js";
import AttendContext from "./context/AttendContext";

const LiveData = ({ showLive, setShowLive, stepComments, commentor }) => {
  const [showAdd, setshowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showComments, setShowComments] = useState(false)
  const navigate = useNavigate();
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [sortOrder, setSortOrder] = useState("desc");
  const [sortField, setSortField] = useState("created_at");
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPrevPage, setHasPrevPage] = useState(false);
  const [data, setData] = useState([]);
    
  const [showPagination, setShowPagination] = useState(true);

  const [tableIsLoading, setTableIsLoading] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);

  const [searchInput, setSearchInput] = useState("");
  const { setStep } = useContext(AttendContext);

  const request = useAxios();

  const { patient_id } = useParams();
  const { visit_step_id } = useParams();

  useEffect(() => {
    getPatientAllergies(1, "created_at", "desc", patient_id, visit_step_id);
  }, [limit]);

  useEffect(() => {
    if (searchInput.length < 3) return;
    async function search(patient_id) {
      setTableIsLoading(true);
      let res = await request({
        method: "GET",
        url: "patient/allergies/search",
        params: {
          value: searchInput,
          patient_id: patient_id,
        },
      });

      if (res === "network-error") {
        setShowLive(false);
        return;
      }

      if (res === "error") return;

      const fuse = new Fuse([...res.alergy_type, ...res.alergen], {
        keys: ["alergy_type", "alergen"],
      });

      const result = fuse.search(searchInput, patient_id);

      let ids = [];
      let new_docs = [];

      for (let i = 0; i < result.length; i++) {
        if (i === limit) {
          break;
        }
        let doc = result[i].item;
        if (ids.includes(doc.id)) {
          continue;
        }
        ids.push(doc.id);
        new_docs.push(doc);
      }

      setData(new_docs);
      
      setShowPagination(false);
      setIsFiltered(true);
      setTableIsLoading(false);
    }

    search(patient_id);
  }, [searchInput]);

  async function getPatientAllergies(
    page = 1,
    sort_field = "created_at",
    sort_order = "desc",
    patient_id = patient_id,
    visit_step_id = visit_step_id,
  ) {
    setTableIsLoading(true);
    let res = await request({
      method: "GET",
      url: "patient/allergies",
      params: {
        page,
        limit,
        sort_field: sort_field,
        sort_order: sort_order,
        patient_id: patient_id,
        visit_step_id: visit_step_id,
      },
      network_error: false,
    });

    if (res === "network-error") {
      setShowLive(false);
      return;
    }

    setTableIsLoading(false);

    if (res === "error") return;

    setData(res.docs);
    
    setHasNextPage(res.hasNextPage);
    setHasPrevPage(res.hasPrevPage);
  }

  async function handleNext() {
    if (hasNextPage) {
      setPage(page + 1);
      getPatientAllergies(page + 1, sortField, sortOrder, patient_id, visit_step_id);
    }
  }

  async function handlePrev() {
    if (hasPrevPage) {
      setPage(page - 1);
      getPatientAllergies(page - 1, sortField, sortOrder, patient_id, visit_step_id);
    }
  }

  async function handleFilterQuery() {
    setSortField("created_at");
    setSortOrder("desc");
    setPage(1);
    getPatientAllergies(1, "created_at", "desc", patient_id, visit_step_id);

    setIsFiltered(true);
  }

  async function handleHeaderQuery(field, order) {
    setPage(1);
    setSortOrder(order);
    setSortField(field);
    getPatientAllergies(1, field, order);
  }

  async function handleClearFilters() {
    setSearchInput("");
    await getPatientAllergies(1, "created_at", "desc", patient_id, visit_step_id);
    setPage(1);
    setSortField("created_at");
    setSortOrder("desc");
    setIsFiltered(false);
    setShowPagination(true);
  }

  return (
    <AllContext.Provider
      value={{
        showLive,
        setShowLive,
        limit,
        setLimit,
        data,
        setData,
        page,
        hasNextPage,
        hasPrevPage,
        handleNext,
        handlePrev,
        handleHeaderQuery,
        handleFilterQuery,
        isFiltered,
        handleClearFilters: handleClearFilters,
        total: "...",
        setIsFiltered,
        showPagination,
        resetQuery: handleClearFilters,
        showEdit,
        setShowEdit,
       
      }}
    >
      <div className="float-right px-12 ">
        <Comment setShowComment={setShowComments} />
      </div>
      
      <div className="p-2">
        <TableTopBar
          showAdd={true}
          showFilter={false}
          showReport={false}
          tableIsLoading={tableIsLoading}
          changes={0}
          handleClearFilters={handleClearFilters}
          handleLiveStream={() => {}}
          AddClick={() => setshowAdd(true)}
          addLabel="Add Allergy"
          searchInput={searchInput}
          setSearchInput={setSearchInput}
          isFiltered={isFiltered}
          mt={2}
          mb={2}
          px={2.3}
        />
      <div>
          <ViewComment 
            stepComments={stepComments}
            showModal={showComments}
            setShowModal={setShowComments}
            commentor={commentor}
          />
          <AllergiesTable />

          <div className="flex justify-center mt-8">
            <BtnRounded click={() => setStep((c) => c + 1)}>
              <div className="gap-x-2 w-[500px] flex justify-center items-center active:opacity-50 cursor-pointer">
                <p>Continue</p>
                <p>
                  <BsArrowRight />
                </p>
              </div>
            </BtnRounded>
          </div>
        </div>

        <AddAllergiesModal setShowModal={setshowAdd} showModal={showAdd} />
      </div>
    </AllContext.Provider>
  );
};

export default LiveData;
