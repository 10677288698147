import { useState, useEffect } from "react";

import BellTopBar from "../../../../../Components/Bars/BellTopBar";

import RequestsTable from "./components/Table/RequestsTable";

import Filter from "./components/FilterModal";

import { TableTopBar } from "../../../../../Components/FinalTable";

import RequestsAll from "./context/RequestsContext";

import { useNavigate } from "react-router-dom";

import useApp from "../../../../../Hooks/useApp";

import useAxios from "./../../../../../Hooks/useAxios";

const AllRequests = ({ showLive, setShowLive }) => {
  const { user } = useApp();

  const [open, setOpen] = useState(false);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [sortOrder, setSortOrder] = useState("desc");
  const [sortField, setSortField] = useState("created_at");
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPrevPage, setHasPrevPage] = useState(false);
  const [data, setData] = useState([]);
  const [showPagination, setShowPagination] = useState(true);
  const [filters, setFilters] = useState({});
  const [tabsData, setTabsData] = useState({});

  const [tableIsLoading, setTableIsLoading] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);

  const request = useAxios();

  const navigate = useNavigate();

  useEffect(() => {
    getInitRequests(1, "created_at", "desc", filters);
  }, [limit]);

  async function getInitRequests(
    page = 1,
    sort_field = "created_at",
    sort_order = "desc",
    filters = {}
  ) {
    setTableIsLoading(true);

    let res = await request({
      method: "GET",
      url: "store/requests",
      params: {
        page,
        sort_field: sort_field,
        sort_order: sort_order,
        limit,
        user_id: user.id,
        ...filters,
      },
      network_error: false,
    });

    if (res === "network-error") {
      setShowLive(false);
      return;
    }

    setTableIsLoading(false);

    if (res === "error") return;

    setTabsData({
      all: res.total_approved + res.total_canceled + res.total_pending,

      approved: res.total_approved,

      canceled: res.total_canceled,

      pending: res.total_pending,
    });
    setData(res.docs);

    setHasNextPage(res.hasNextPage);

    setHasPrevPage(res.hasPrevPage);
  }

  async function handleNext() {
    if (hasNextPage) {
      setPage(page + 1);
      getInitRequests(page + 1, sortField, sortOrder, filters);
    }
  }

  async function handlePrev() {
    if (hasPrevPage) {
      setPage(page - 1);
      getInitRequests(page - 1, sortField, sortOrder, filters);
    }
  }

  async function handleFilterQuery(filters) {
    setSortField("created_at");
    setSortOrder("desc");
    getInitRequests(1, "created_at", "desc", filters);

    setIsFiltered(true);
    setFilters(filters);
  }

  async function handleHeaderQuery(field, order) {
    setPage(1);
    setSortOrder(order);
    setSortField(field);
    getInitRequests(1, field, order);
  }

  async function handleClearFilters() {
    await getInitRequests(1, "created_at", "desc");
    setPage(1);
    setSortField("created_at");
    setSortOrder("desc");
    setIsFiltered(false);
    setShowPagination(true);
  }

  return (
    <RequestsAll.Provider
      value={{
        showLive,
        setShowLive,
        limit,
        setLimit,
        data,
        page,
        hasNextPage,
        hasPrevPage,
        handleNext,
        handlePrev,
        handleHeaderQuery,
        handleFilterQuery,
        isFiltered,
        handleClearFilters: handleClearFilters,
        total: "...",
        setIsFiltered,
        showPagination,
        tabsData,
        resetQuery: handleClearFilters,
      }}
    >
      <div className="bg-ghost_white h-full pb-4">
        <BellTopBar label={"Requests"} />
        <div className="flex justify-end">
          <TableTopBar
            showSearch={false}
            showAdd={true}
            addLabel="Add request"
            AddClick={() => navigate("/dashboard/requests/add")}
            showFilter={true}
            handleClearFilters={handleClearFilters}
            isFiltered={isFiltered}
            tableIsLoading={tableIsLoading}
            showReport={false}
            changes={0}
            FilterClick={() => setOpen(true)}
            mt={2}
            mb={2}
            px={1.5}
          />
        </div>

        <RequestsTable />

        <Filter open={open} setOpen={setOpen} />
      </div>
    </RequestsAll.Provider>
  );
};

export default AllRequests;
