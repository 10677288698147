import React, { useState, useEffect } from "react";

import BellTopBar from "../../../../../Components/Bars/BellTopBar";

import { TableTopBar } from "../../../../../Components/FinalTable";

import TFilter from "./Components/TableComponent/TFilter";

import { useNavigate } from "react-router-dom";

import AllContext from "./AllContext";

import TableAll from "./Components/TableComponent/Table";

import useAxios from "./../../../../../Hooks/useAxios";

import Fuse from "fuse.js";

const AllPatients = ({ showLive, setShowLive }) => {
  const navigate = useNavigate();

  const [openFilter, setOpenFilter] = useState(false);

  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [sortOrder, setSortOrder] = useState("desc");
  const [sortField, setSortField] = useState("created_at");
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPrevPage, setHasPrevPage] = useState(false);
  const [data, setData] = useState([]);
  const [showPagination, setShowPagination] = useState(true);
  const [filters, setFilters] = useState({});

  const [tableIsLoading, setTableIsLoading] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);

  const [searchInput, setSearchInput] = useState("");

  const request = useAxios();

  useEffect(() => {
    getInitPatients(1, "created_at", "desc", filters);
  }, [limit]);

  useEffect(() => {
    if (searchInput.length < 3) return;
    async function search() {
      setTableIsLoading(true);
      let res = await request({
        method: "GET",
        url: "patient/search",
        params: {
          value: searchInput,
        },
      });

      if (res === "network-error") {
        setShowLive(false);
        return;
      }

      if (res === "error") return;

      const fuse = new Fuse(
        [...res.first_name, ...res.second_name, ...res.sir_name, ...res.email],
        {
          keys: ["first_name", "second_name", "sir_name", "email"],
        }
      );

      const result = fuse.search(searchInput);

      let ids = [];
      let new_docs = [];

      for (let i = 0; i < result.length; i++) {
        if (i === limit) {
          break;
        }
        let doc = result[i].item;
        if (ids.includes(doc.id)) {
          continue;
        }
        ids.push(doc.id);
        new_docs.push(doc);
      }
      setData(new_docs);

      setShowPagination(false);
      setIsFiltered(true);
      setTableIsLoading(false);
    }

    search();
  }, [searchInput]);

  async function getInitPatients(
    page = 1,
    sort_field = "created_at",
    sort_order = "desc",
    filters = {}
  ) {
    setTableIsLoading(true);
    let res = await request({
      method: "GET",
      url: "patient",
      params: {
        page,
        limit,
        sort_field: sort_field,
        sort_order: sort_order,
        ...filters,
      },
      network_error: false,
    });

    if (res === "network-error") {
      setShowLive(false);
      return;
    }

    setTableIsLoading(false);

    if (res === "error") return;

    setData(res.docs);
    setHasNextPage(res.hasNextPage);
    setHasPrevPage(res.hasPrevPage);
  }

  async function handleNext() {
    if (hasNextPage) {
      setPage(page + 1);
      getInitPatients(page + 1, sortField, sortOrder);
    }
  }

  async function handlePrev() {
    if (hasPrevPage) {
      setPage(page - 1);
      getInitPatients(page - 1, sortField, sortOrder);
    }
  }

  async function handleFilterQuery(filters) {
    setSortField("created_at");
    setSortOrder("desc");
    setPage(1);
    getInitPatients(1, "created_at", "desc", filters);

    setIsFiltered(true);
    setFilters(filters);
  }

  async function handleHeaderQuery(field, order) {
    setPage(1);
    setSortOrder(order);
    setSortField(field);
    getInitPatients(1, field, order);
  }

  async function handleClearFilters() {
    setSearchInput("")
    await getInitPatients(1, "created_at", "desc");
    setPage(1);
    setSortField("created_at");
    setSortOrder("desc");
    setIsFiltered(false);
    setShowPagination(true);
  }

  return (
    <AllContext.Provider
      value={{
        showLive,
        limit,
        setLimit,
        data,
        page,
        hasNextPage,
        hasPrevPage,
        handleNext,
        handlePrev,
        handleHeaderQuery,
        handleFilterQuery,
        isFiltered,
        handleClearFilters: handleClearFilters,
        total: "...",
        setIsFiltered,
        showPagination,
        resetQuery: handleClearFilters,
      }}
    >
      <div className="w-full h-screen  bg-ghost_white">
        <div className="w-full bg-ghost_white">
          <BellTopBar label="Patients" />
        </div>
        <div className="w-full px-7 pb-8">
          <TableTopBar
            FilterClick={() => setOpenFilter(true)}
            mt={2}
            mb={2}
            addLabel="Add"
            showAdd={false}
            AddClick={() => navigate("add")}
            changes={0}
            handleLiveStream={() => {}}
            isFiltered={isFiltered}
            handleClearFilters={handleClearFilters}
            tableIsLoading={tableIsLoading}
            showReport={false}
            showFilter={true}
            searchInput={searchInput}
            setSearchInput={setSearchInput}
          />
          <TableAll />
          {openFilter && (
            <TFilter showFilter={openFilter} setShowFilter={setOpenFilter} />
          )}
        </div>
      </div>
    </AllContext.Provider>
  );
};
export default AllPatients;
