import { useState, useEffect } from 'react';
import axios from 'axios';

import Infosection from './Infosection';
import TableDetails from "./TableDetails";
import TableHeading from "./TableHeading";

import { Table } from "../../../../../../../../Components/FinalTable";

import PrescriptionsAll from "../../context/PrescriptionsContext";

const PrescriptionTable = ({url, id}) => {
  
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);

  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);

  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPrevPage, setHasPrevPage] = useState(false);

  const db_name = "visit_prescription"
    
  const Title = [
    {id:1, name:"MEDICINE"},
    { id:2, name: "DOSAGE"},
    { id:3, name: "NO.OF DAYS"},
    { id:4, name: "FREQUECNY"},
    { id:5, name: "STATUS"},
    { id:6, name: "PAYMENT"},
    { id:7, name: "DATE"},
  ];
  const showPagination = true;
  
  useEffect(()=>{
    const getData = async()=>{

      const doc = await axios({
        url:`${url}/patient/history?patient_id=${id}&type=${db_name}&page=${page}&limit=${limit}`
      });

      setData(doc.data.docs);
  
      setHasNextPage(doc.data.hasNextPage);
  
      setHasPrevPage(doc.data.hasPrevPage);
  
      setTotal(doc.data.total_docs);

  }
  
  getData();
  // eslint-disable-next-line
  },[ limit, page]);

  useEffect(()=>{
    setPage(1);
  },[limit])

  function handleNext(){
    setPage(page+1)
  }

  function handlePrev(){
    setPage(page-1)
  }

  return (
    <PrescriptionsAll.Provider
    value={{
      limit,
      setLimit,
      data,
      setData,
      page,
      hasNextPage,
      hasPrevPage,
      handleNext,
      handlePrev,
      total,
      showPagination,
    }}>
      <div className="mt-5">
        <div className="flex justify-between w-[78%] pl-4 pt-6 mt-10">
          <h2 className="font-medium text-2xl">Prescription List</h2>
        </div>
        <div>
          <Table>
            <TableHeading Titles={Title} />
            <TableDetails data={data}/>
            <Infosection />
          </Table>
        </div>
          
        
      </div>
    </PrescriptionsAll.Provider>
  );

}

export default PrescriptionTable;
