import {  } from '../../../../../../../Components/FinalTable';

import TableHeader from '../../../../../../../Components/FinalTable/components/TableHeader';

import HeaderBtn from '../../../../../../../Components/FinalTable/components/Btns/HeaderBtn';


const TableHeading =()=>{

    return (
        <div>
            <TableHeader >
                <HeaderBtn w={90 / 6} label="ALLERGY TYPE"  />
                <HeaderBtn w={90 / 6} label="ALLERGEN"  />
                <HeaderBtn w={90 / 6} label="REACTION"  />
                <div className=" flex-1 invisible flex justify-center gap-x-4 text-3xl text-primary">
                    <span
                        className=" active:opacity-50 cursor-pointer text-red"
                        onClick={() => {}}
                    >                        
                    </span>
                </div>
            </TableHeader>
        </div>
    )
}
export default TableHeading;