import React, { useContext } from "react";
import TRow from "./TRow";

import AllContext from "../context/AllContext";

const TBody = () => {
  const { data } = useContext(AllContext);

  return (
    <div className="w-full ">
      {data.map((doc) => (
        <TRow doc={doc} key={doc._id || doc.id} />
      ))}
    </div>
  );
};

export default TBody;
