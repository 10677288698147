import NumberInput from "../../../../../../Components/Inputs/NumberInput";
import TextInput from "../../../../../../Components/Inputs/TextInput";
import TextBox from "../../../../../../Components/Inputs/TextBox";
import BtnRounded from "../../../../../../Components/Buttons/BtnRounded";
import { useRef, useState } from "react";
import useHandleFormError from "../../../../../../Hooks/useHandleFormError";
import useApp from "../../../../../../Hooks/useApp";
import useAxios from "../../../../../../Hooks/useAxios";
import usePouchCud from "../../../../Hooks/usePouchCud";
import { useNavigate } from "react-router-dom";

const MoreVitals = ({ vitals, visitId }) => {
  const input = useRef({ form: {}, err: {} });

  const [showError, setShowError] = useState(0);

  const formErrors = useHandleFormError();

  const { setModalMessage, setShowFormModal, setShowSuccessModal } = useApp();

  const { user } = useApp();

  const navigate = useNavigate();

  const { create } = usePouchCud();

  const request = useAxios();

  async function submit() {
    setShowError(showError + 1);

    if (formErrors({ input })) return;

    let data = {
      visit_id: parseInt(visitId),
      user_id: parseInt(user.id),
      height: input?.current?.form?.height || null,
      weight: input?.current?.form?.weight || null,
      temparature: input?.current?.form?.temparature || null,
      diastolic_bp: input?.current?.form?.diastolic_bp || null,
      systolic_bp: input?.current?.form?.systolic_bp || null,
      blood_preasure: input?.current?.form?.blood_preasure || null,
      respiratory_rate: input?.current?.form?.respiratory_rate || null,
      blood_sugar: input?.current?.form?.blood_sugar || null,
      bmi: input?.current?.form?.bmi || null,
      pulse_rate: input?.current?.form?.pulse_rate || null,
      sp02: input?.current?.form?.sp02 || null,
      comments: input?.current?.form?.comments || "",
    };

    if (vitals !== null) {
      data.id = parseInt(vitals.id);
    }

    let res = await request({
      method: `${vitals === null ? `POST` : `PUT`}`,
      url: "hospital/vitals",
      data: data,
    });

    if (res !== "error") {
      await create({ name: "visit_vitals", doc: res });
      await setModalMessage(
        `${vitals === null ? `Patient Vitals Added` : `Patient Vitals Updated`}`
      );
      await setShowSuccessModal(true);
      navigate(`/dashboard/next-step/${visitId}`);
    }
  }

  return (
    <div className="w-[60%] mx-auto pb-4 bg-white shadow-md rounded-lg px-5 mt-5 mb-4">
      <div className="p-4 grid grid-cols-3 gap-x-6">
        <TextInput
          initial={vitals !== null ? vitals.systolic_bp : ""}
          input={input}
          showError={showError}
          inputKey="pulse_rate"
          mt={0.5}
          config={{
            required: false,
            label: "Pulse Rate",
            placeholder: "",
            max: 99999999999,
            min: 0,
          }}
        />

        <TextInput
          initial={vitals !== null ? vitals.diastolic_bp : ""}
          input={input}
          showError={showError}
          inputKey="sp02"
          mt={0.5}
          config={{
            required: false,
            label: "SpO2 Level",
            max: 99999999999,
            min: 0,
          }}
        />

        <TextInput
          initial={vitals !== null ? vitals.weight : ""}
          input={input}
          showError={showError}
          inputKey="weight"
          mt={0.5}
          config={{
            required: false,
            label: "Weight(kg)",
            max: 99999999999,
            min: 0,
          }}
        />

        <TextInput
          initial={vitals !== null ? vitals.height : ""}
          input={input}
          showError={showError}
          inputKey="height"
          mt={0.5}
          config={{
            required: false,
            label: "Height (cm)",
            max: 99999999999,
            min: 0,
          }}
        />
        <TextInput
          initial={vitals !== null ? vitals.temparature : ""}
          input={input}
          showError={showError}
          inputKey="temparature"
          mt={0.5}
          config={{
            required: false,
            label: "Temperature (°C)",
            max: 99999999999,
            min: 0,
          }}
        />

        <TextInput
          initial={vitals !== null ? vitals.blood_preasure : ""}
          input={input}
          showError={showError}
          inputKey="blood_preasure"
          mt={0.5}
          config={{
            required: false,
            label: "Blood Pressure (B.P)",
            max: 99999999999,
            min: 0,
          }}
        />

        <TextInput
          initial={vitals !== null ? vitals.respiratory_rate : ""}
          input={input}
          showError={showError}
          inputKey="respiratory_rate"
          mt={0.5}
          config={{
            required: false,
            label: "Respiratory Rate",
            max: 99999999999,
            min: 0,
          }}
        />

        <TextInput
          initial={vitals !== null ? vitals.blood_sugar : ""}
          input={input}
          showError={showError}
          inputKey="blood_sugar"
          mt={0.5}
          config={{
            required: false,
            label: "Blood sugar",
            max: 99999999999,
            min: 0,
          }}
        />

        <TextInput
          initial={vitals !== null ? vitals.blood_sugar : ""}
          input={input}
          showError={showError}
          inputKey="bmi"
          mt={0.5}
          config={{
            required: false,
            label: "BMI",
            max: 99999999999,
            min: 0,
          }}
        />
      </div>
      <TextBox
        input={input}
        inputKey="comments"
        initial={vitals !== null ? vitals.comments : ""}
        showError={showError}
        config={{
          disabled: false,
          required: false,
          label: "Comments",
          placeholder: "Enter Comment",
          minChar: 0,
          maxChar: 200,
          cols: 6,
        }}
      />
      <div className="block my-3 mb-3">
        <BtnRounded click={() => submit()}>Submit</BtnRounded>
      </div>
    </div>
  );
};

export default MoreVitals;
