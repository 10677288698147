import {
    TableInfoRow,
    TablePagination,
    TableInfoCell
} from "../../../../../../../Components/FinalTable";
import React,{useContext} from "react";
import AllContext from "../context/AllContext";


const TInfo = () => {
    const {
      limit,
      setLimit,
      total,
      data,
      page,
      hasNextPage,
      hasPrevPage,
      handleNext,
      handlePrev,
      showPagination,
    } = useContext(AllContext);
    return (
      <TableInfoRow mt={20}>
        <TableInfoCell
          limit={limit}
          setLimit={setLimit}
          total={total}
          docs={data}
        />
        {showPagination ? (
          <TablePagination
            page={page}
            hasNextPage={hasNextPage}
            hasPrevPage={hasPrevPage}
            nextClick={handleNext}
            prevClick={handlePrev}
          />
        ) : null}
      </TableInfoRow>
    );
  };
  
  export default TInfo;
  