import React from "react"
import { Table } from "../../../../../../../Components/FinalTable";
import TableHeading from "./TableHeading";
import TBody from "./TBody";
import TContext from "./TContext";
import TInfo from "./TInfo";


const AllergiesTable = () => {
     
  return (
   
      <div className="mx-5 p-5 mb-4">
        <Table>
          <TableHeading />
          <TBody />
          <TInfo />
        </Table>
      </div>
   
    
  );
};

export default AllergiesTable;
