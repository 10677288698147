import { BsStack } from "react-icons/bs";

import BtnTemplate from "../BtnTemplate";

import { useLocation, useNavigate } from "react-router-dom";

import Tooltip from "../../ToolTip";

const ReceptionistQueue = ({ open }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const id = "receptionist-queue-tooltip";

  return (
    <div data-tip data-for={id} className="w-full">
      <BtnTemplate
        label={"Queue"}
        open={open}
        active={location.pathname.includes("dashboard/user-queue")}
        click={() => navigate("user-queue")}
      >
        <BsStack />
      </BtnTemplate>
      <Tooltip
        id={id}
        txt1="Triage Queue"
        txt2="Patients waiting for Doctor attendance"
      >
        <BsStack />
      </Tooltip>
    </div>
  );
};

export default ReceptionistQueue;
