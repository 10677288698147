import React, { useState, useEffect } from "react";

import FormScreen from "../../../../Components/Templates/FormScreen";
import FormLable from "../../../../Components/Label/FormLabel";
import Screen from "./Components/Screen";
import Steps from "./Components/Steps";
import { useNavigate, useParams } from "react-router-dom";
import AttendContext from "./Pages/Allergies/context/AttendContext"
import useAxios from "../../../../Hooks/useAxios";


const Attend = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState(-1);
  
  const {visit_step_id} = useParams()
  const [patient, setPatient] = useState({})
  const [vitals, setVitals] = useState({})
  const [stepId, setStepId] = useState(null)
  const [visitId, setVisitId] = useState(null)
  const [stepComments, setStepComments] = useState("")
  const [commentor, setCommentor] = useState("")
  const request = useAxios();
  const [pagename, setPagename] = useState("Allergies")
   
  function handleBack() {
    setStep((c) => c - 1);
  }

  useEffect(() => {
    async function getPageName() {
      try {
        if (step === -1) {
          setPagename("Allergies")
        }
        if (step === 0) {
          setPagename("Vitals")
        }
      } catch (error) {
        
      }
    }
    getPageName()
  
  }, [step])

  useEffect(() => {
    Triage()
  }, [])


  async function Triage() {
    let res = await request({
      method: "GET",
      url : "hospital/triage/attendance",
      params : {
        visit_step_id : visit_step_id,
      }
    });
    setPatient(res.visits.patients)
    setVitals(res.visits.visit_vitals)
    setStepId(res.id)
    setVisitId(res.visit_id)
    setStepComments(res.comments)
    setCommentor(res.team_visit_steps_step_created_byToteam.name)
    
  }
 
  return (
    <AttendContext.Provider
      value={{
        step,
        setStep,
        
      }}
    >
      <FormScreen backBtn={ step === -1 ? false : true } back={() => handleBack()}>
        <div className="bg-ghost-white shadow-md py-2">
          <FormLable
            label={`${pagename} - ${patient.first_name} ${patient.second_name}`}
            mt={1}
            mb={1}
          />
        </div>
        <div className="mt-4 pt-4">
          <Steps
            currentStep={step}
            close={() => navigate("/dashboard/user-queue")}
          />
        </div>
        <div className="w-full">
          <Screen
            step={step} 
            setStep={setStep} 
            vitals={vitals} 
            stepId={stepId} 
            visitId={visitId} 
            stepComments={stepComments}
            commentor={commentor}
          />
        </div>
      </FormScreen>
    </AttendContext.Provider>
  
  );
};

export default Attend;
