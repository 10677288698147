import React from "react";
import { useState } from "react";
import { TableTopBar } from "../../../../../../Components/FinalTable";
import BtnRounded from "../../../../../../Components/Buttons/BtnRounded";
import useFuzzySearch from "../../../../../../Hooks/useFuzzySearch";
import usePouchPagination from "../../../../../../Hooks/usePouchPagination";
import useFeatures from "../../../../Hooks/useFeatures";
import { BsArrowRight } from "react-icons/bs";

import AddAllergiesModal from "./Modals/AddAllergiesModal";
import AllContext from "./context/AllContext";
import AllergiesTable from "./All/index";
// import EditAllergiesModal from "./Modals/EditAllergiesModal";
import { useParams } from "react-router-dom";

const PouchData = ({ setStep }) => {
  const [showAdd, setshowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [limit, setLimit] = useState(10);
  const [data, setData] = useState([]);
  const [showPagination, setShowPagination] = useState(true);
  const { patient_allergies, resetChanges } = useFeatures();
  const [tableIsLoading, setTableIsLoading] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);

  const [searchInput, setSearchInput] = useState("");
  const [fixed, setFixed] = useState({});

  const db_name = "patient_allergies";

  const initialIndex = ["created_at"];

  const { patient_id } = useParams();

  const initialSort = [{ created_at: "desc" }];

  const initialSelector = {
    created_at: { $gte: null },
  };

  const searchOptions = {
    keys: ["alergy_type", "alergen", "reaction"],
    minMatchCharLength: 3,
    threshold: 0.5,
  };

  const {
    hasNextPage,
    hasPrevPage,
    handleNext,
    handlePrev,
    handleHeaderQuery,
    handleFilterQuery,
    resetQuery,
    page,
    total,
  } = usePouchPagination({
    db_name,
    limit,
    initialSort,
    initialIndex,
    initialSelector,
    setData,
    setTableIsLoading,
    fixed: { patient_id: patient_id },
  });

  useFuzzySearch({
    db_name,
    input: searchInput,
    options: searchOptions,
    limit,
    setShowPagination,
    setIsFiltered,
    setTableIsLoading,
    setData,
    fixed: { patient_id: patient_id },
  });

  async function handleClearFilters() {
    setFixed({});
    await resetQuery();

    setIsFiltered(false);
    setSearchInput("");
  }

  async function handleLiveStream() {
    resetChanges("patient_allergies");
    resetQuery();
  }

  return (
    <AllContext.Provider
      value={{
        limit,
        setLimit,
        data,
        setData,
        page,
        hasNextPage,
        hasPrevPage,
        handleNext,
        handlePrev,
        handleHeaderQuery,
        handleFilterQuery,
        isFiltered,
        handleClearFilters,
        total,
        setIsFiltered,
        showPagination,
        setFixed,
        showEdit,
        setShowEdit,
        resetQuery,
      }}
    >
      <div className="h-[750px] pb-4 mb-4 px-4">
        <TableTopBar
          showAdd={true}
          showFilter={false}
          showReport={false}
          changes={patient_allergies.changes}
          tableIsLoading={false}
          handleLiveStream={handleLiveStream}
          handleClearFilters={handleClearFilters}
          AddClick={() => setshowAdd(true)}
          addLabel="Add Allergy"
          searchInput={searchInput}
          setSearchInput={setSearchInput}
          mt={2}
          mb={2}
          px={2.3}
        />
        <div className="flex flex-col">
          <AllergiesTable />

          <div className="flex justify-center mt-14 pl-10">
            <BtnRounded click={() => setStep((c) => c + 1)}>
              <div className="gap-x-2 w-[500px] flex justify-center items-center active:opacity-50 cursor-pointer">
                <p>Continue</p>
                <p>
                  <BsArrowRight />
                </p>
              </div>
            </BtnRounded>
          </div>
        </div>

        <AddAllergiesModal setShowModal={setshowAdd} showModal={showAdd} />
        
      </div>
    </AllContext.Provider>
  );
};

export default PouchData;
