import React from "react";
import { useState } from "react";

import LiveData from "./LiveData";
import PouchData from "./PouchData";

const AllergiesList = ({ stepComments, commentor}) => {
  const [showLive, setShowLive] = useState(true);

  if (showLive) {
    return ( <LiveData showLive={showLive} setShowLive={setShowLive} stepComments={stepComments} commentor={commentor} /> )
  }
  return ( <PouchData showLive={showLive} setShowLive={setShowLive} /> )
}
  

export default AllergiesList;
