import { ActionBtn } from "../../../../../../../Components/FinalTable";
import { FiEdit2 } from "react-icons/fi";
import {BiTrash} from 'react-icons/bi'
import { useContext, useState } from "react";
import useAxios from "../../../../../../../Hooks/useAxios";
import usePouchCud from "../../../../../Hooks/usePouchCud";
import AllContext from "../context/AllContext";
import useApp from "../../../../../../../Hooks/useApp";
import EditAllergiesModal from "../Modals/EditAllergiesModal";


const TableActions = ({doc}) => {
  const {showEdit, setShowEdit} = useContext(AllContext);
  const { data, setData} = useContext(AllContext);
  const  request = useAxios();
  const {deleteDoc} = usePouchCud()
  const { setModalMessage, setShowSuccessModal } = useApp();
  

  async function handleDelete() {
    let res = await request({
      method: "DELETE",
      url: `patient/allergy/${doc.id}`  
    });

    if (res !== "error") {
      setModalMessage("Patient Allergy Deleted");
      setShowSuccessModal(true);
      await deleteDoc({name:"patient_allergies", doc:res});
      let newDocs = data.filter((row) => (row.id === doc.id ? false : true));
      setData(newDocs);
    }

  }
  
  return (
    <div className="flex-1" style={{width:"45vw"}}>
      <ActionBtn showNext={false}>
        <div className="flex flex-col text-sm text-black">
          <div
           className="px-3 py-2 gap-x-2 flex items-center active:opacity-50 cursor-pointer"
           onClick={ () => setShowEdit(true) }
           >
            <p className="">
              <FiEdit2 />
            </p>
            <p className="font-bold">Edit Allergy</p>
          </div>
          <div className="px-3 py-2 text-red gap-x-2 flex items-center active:opacity-50 cursor-pointer">
            <p className="">
              <BiTrash />
            </p>
            <p className="font-bold" onClick={ () => handleDelete() }>Delete Allergy</p>
          </div>
        </div>
        <div className="text-black text-base">
          <EditAllergiesModal 
            setShowModal={setShowEdit} 
            showModal={showEdit} 
            doc={doc}
          />
        </div>
        
      </ActionBtn>
      
    </div>
  );
};

export default TableActions;
