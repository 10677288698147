import Allergies from "../Pages/Allergies/index";

import MoreVitals from "../Pages/Vitals/MoreVitals"

const Screen = ({ step, setStep, vitals, stepId, visitId, stepComments, commentor }) => {
  if (step === -1) {
    return <Allergies setStep={setStep} stepComments={stepComments} commentor={commentor} />;
  }
  if (step === 0) {
    return <MoreVitals setStep={setStep} vitals={vitals} stepId={stepId} visitId={visitId} />;
  }
  return null;
};

export default Screen;
