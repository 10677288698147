import Steppers from "../../../../../Components/Steppers/Steppers";

import { TbHeartbeat } from "react-icons/tb";

import { CgFileDocument } from "react-icons/cg";

const Steps = ({ currentStep, close }) => {
  return (
    <Steppers currentStep={currentStep} close={close}>
      <div className="flex flex-row items-center gap-x-3 font-light text-white ">
        <CgFileDocument color="white" />
        <p>ALLERGIES</p>
      </div>
      <div className="flex flex-row items-center gap-x-3 font-light text-white ">
        <TbHeartbeat color="white" />
        <p>VITALS</p>
      </div>
    </Steppers>
  );
};

export default Steps;
