import Details from "./components/Details";
import AllergiesTable from "./components/AllergiesTable/components/Table";
import DiagnosisTable from "./components/DiagnosisTable/components/Table";
import InvestigationTable from "./components/InvestigationTable/components/Table";
import PrescriptionTable from "./components/PrescriptionTable/components/Table";
import ProcedureTable from "./components/ProcedureTable/components/Table";

import BellTopBar from "../../../../Components/Bars/BellTopBar";
import CancelBar from "../../../../Components/Steppers/CancelBar";

import useApp from "../../../../Hooks/useApp";

import { useParams, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";

const MedicalHistory = () => {
  const [patient, setPatient] = useState({
    gender: "",
    phone: "",
    occupation: "",
    email: "",
    dob: "000000000",
  });

  const [diagnoses, setDiagnoses] = useState("");

  const { setIsLoading } = useApp();

  const { id } = useParams();

  const navigate = useNavigate();

  const url = "https://prod.api.mkombozihealthcare.co.tz/api";

  useEffect(() => {
    async function initialize() {
      try {
        setIsLoading(true);

        let patient = await axios({
          url: `${url}/patient/${id}`,
        });

        let diagnosis = await axios({
          url: `${url}/patient/history?patient_id=${id}&type=visit_notes&page=1&limit=1`,
        });

        setDiagnoses(diagnosis.data.docs[0]?.diagnosis);

        setPatient(patient.data.patient);
      } catch (error) {
        navigate("../");
      } finally {
        setIsLoading(false);
      }
    }
    initialize();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="w-full bg-ghost_white pb-4">
      <BellTopBar label={(patient.first_name || "") + "- Medical History"} />
      <div className="mt-8 mb-8">
        <CancelBar />
      </div>
      <div className="ml-5 mt-5 p-5 px-8 mb-4 mr-5 bg-white rounded-lg shadow-md ">
        <Details patient={patient} condition={diagnoses} />

        <DiagnosisTable url={url} id={id} />
        <AllergiesTable url={url} id={id} />
        <InvestigationTable url={url} id={id} />
        <ProcedureTable url={url} id={id} />
        <PrescriptionTable url={url} id={id} />
      </div>
    </div>
  );
};

export default MedicalHistory;
