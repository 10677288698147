import React, { useState, useEffect, useContext } from "react";

import { TableHeaderRow } from "../../../../../../../Components/FinalTable";

import HeaderBtnSort from "../../../../../../../Components/FinalTable/components/Btns/HeaderBtnSort";

import AllContext from "../../AllContext";

const genders = ["Male", "Female", "Other"];

const THead = () => {
  const { handleHeaderQuery, showLive, handleFilterQuery, setIsFiltered } =
    useContext(AllContext);

  const [name, setName] = useState(true);
  const [email, setEmail] = useState(true);
  const [phone, setPhone] = useState(true);
  const [id_no, setIdNo] = useState(true);
  const [dob, setDob] = useState(true);
  const [gender, setGender] = useState(0);
  const [genderSort, setGenderSort] = useState(true);

  function handleNameSort() {
    if (showLive) {
      handleHeaderQuery("first_name", name ? "asc" : "desc");
      setName(!name);
      return;
    }
  }

  function handleEmailSort() {
    if (showLive) {
      handleHeaderQuery("email", email ? "asc" : "desc");
      setEmail(!email);
      return;
    }
  }

  function handlePhoneSort() {
    if (showLive) {
      handleHeaderQuery("phone", phone ? "asc" : "desc");
      setPhone(!phone);
      return;
    }
  }

  function handleIdSort() {
    if (showLive) {
      handleHeaderQuery("id_no", id_no ? "asc" : "desc");
      setIdNo(!id_no);
      return;
    }
  }

  function handleDoBSort() {
    if (showLive) {
      handleHeaderQuery("id_no", dob ? "asc" : "desc");
      setDob(!dob);
      return;
    }
  }

  function handleGenderFilter() {
    if (showLive) {
      handleHeaderQuery("gender", genderSort ? "asc" : "desc");
      setGenderSort(!genderSort);
      return;
    }
  }

  return (
    <TableHeaderRow>
      <HeaderBtnSort w={90 / 6} label="NAME" click={handleNameSort} />
      <HeaderBtnSort w={90 / 6} label="EMAIL" click={handleEmailSort} />
      <HeaderBtnSort w={90 / 6} label="PHONE" click={handlePhoneSort} />
      <HeaderBtnSort w={90 / 6} label="NIDA" click={handleIdSort} />
      <HeaderBtnSort w={90 / 6} label="DOB" click={handleDoBSort} />
      <HeaderBtnSort w={90 / 6} label="GENDER" click={handleGenderFilter} />
      <div style={{ width: "10%" }}></div>
    </TableHeaderRow>
  );
};

export default THead;
