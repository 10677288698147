import React,{useState} from "react";

import { Table } from "../../../../../../Components/FinalTable";

import THead from "./THead";

import TBody from "./TBody";

import TInfo from "./TInfo";

import TContext from "./TContext"

const TableAll = () => {
  

  return (
    <TContext.Provider
      value={{ }}
    >
      <Table>
        <THead />
        <TBody />
        <TInfo />
      </Table>
    </TContext.Provider>
    
  );
};

export default TableAll;
